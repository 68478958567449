import axios from 'axios';
import Sentry from '../App/Sentry';
import { Severity } from '@sentry/types';
import { Analytics } from '@segment/analytics-next';

interface Amplitude {
  regenerateDeviceId: () => void;
  setUserId: (string?) => void;
  resetSessionId: () => void;
  getInstance: () => {
    getSessionId: () => string;
    options: {
      deviceId: string;
    };
  };
}

declare let analytics: Analytics | null;
declare let amplitude: Amplitude | null;

interface TelemetryData {
  anonymousId: string;
  amplitude?: {
    deviceId?: string;
    sessionId?: string;
  };
  cookies?: string;
  search?: string;
  userId?: string;
}

export function createTelemetryEntry(userId?: string): void {
  try {
    analytics
      ?.ready(() => {
        try {
          const anonymousId = analytics?.user()?.anonymousId();

          const telemetryData: TelemetryData = {
            anonymousId: anonymousId,
            cookies: document.cookie,
            search: document.location.search,
          };

          const _amplitude = amplitude?.getInstance();
          if (_amplitude) {
            telemetryData.amplitude = {
              deviceId: _amplitude.options?.deviceId,
              sessionId: _amplitude.getSessionId(),
            };
          }

          if (userId) {
            telemetryData.userId = userId;
          }

          axios
            .post(`${process.env.V2_API_URL}/telemetry/entry`, telemetryData)
            .then()
            .catch((e) => {
              Sentry.withScope((scope) => {
                scope.setLevel(Severity.Warning);
                Sentry.captureException(e);
              });
            });
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setLevel(Severity.Warning);
            Sentry.captureException(e);
          });
        }
      })
      .then()
      .catch((e) => {
        Sentry.withScope((scope) => {
          scope.setLevel(Severity.Warning);
          Sentry.captureException(e);
        });
      });
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Warning);
      Sentry.captureException(e);
    });
  }
}
