import React, { useEffect, useState } from 'react';
import LoadingScreen from '../../App/Screens/LoadingScreen';
import { FullScreenContent } from '../../App/Components/SimpleComponents';
import { UserTracking } from '../user-tracking/UserTracking';
import Tracking from '../user-tracking/user-tracking';
import bootstrapAsync from '../../bootstrap';
import { WaffleHeaderText } from '../../App/Components/WaffleText';
import { UserTrackingProvider } from '../user-tracking/UserTrackingProvider';
import { createFeatureFlagClient, FlagProvider } from '../feature-flags';
import { LifeInsuranceProvider } from '../life';

type BootstrapContextProps = {
  isLoading: boolean;
  error: string;
};

const BootstrapContext = React.createContext<BootstrapContextProps>(null);

type BootstrapProviderProps = {
  children: React.ReactNode;
};

let userTracking: UserTracking;
let flagClient;
export function BootstrapProvider({ children }: BootstrapProviderProps) {
  const [state, setState] = useState({
    isLoading: true,
    error: '',
  });

  async function _bootstrapAsync() {
    userTracking = new Tracking();
    await userTracking.initTracking();

    flagClient = await createFeatureFlagClient();

    await bootstrapAsync(userTracking);
  }
  useEffect(() => {
    (async () => {
      setState({
        isLoading: true,
        error: '',
      });
      try {
        await _bootstrapAsync();
        setState({
          ...state,
          isLoading: false,
        });
      } catch (e) {
        console.error(e);
        setState({
          isLoading: false,
          error: e.message,
        });
      }
    })();
  }, []);

  if (state.isLoading) {
    return (
      <FullScreenContent>
        <LoadingScreen />
      </FullScreenContent>
    );
  }

  if (state.error) {
    return (
      <FullScreenContent>
        <WaffleHeaderText>
          {`Error Loading Application`}
          {state.error}
        </WaffleHeaderText>
      </FullScreenContent>
    );
  }

  return (
    <BootstrapContext.Provider
      value={{ isLoading: state.isLoading, error: state.error }}>
      <UserTrackingProvider userTracking={userTracking}>
        <LifeInsuranceProvider>
          <FlagProvider featureFlagClient={flagClient}>{children}</FlagProvider>
        </LifeInsuranceProvider>
      </UserTrackingProvider>
    </BootstrapContext.Provider>
  );
}
