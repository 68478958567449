import MaterialCommunity_ttf from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import Evil_ttf from 'react-native-vector-icons/Fonts/EvilIcons.ttf';
import Material_ttf from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import FontAwesome_ttf from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import CircularStd_med_otf from '../src/assets/fonts/CircularStd-Book.otf';
import SatoshiMedium from '../src/assets/fonts/Satoshi-Medium.otf';
import SatoshiBlack from '../src/assets/fonts/Satoshi-Black.otf';
import SatoshiLight from '../src/assets/fonts/Satoshi-Light.otf';
import SatoshiRegular from '../src/assets/fonts/Satoshi-Regular.otf';
import ViceCitySans from '../src/assets/fonts/ViceCitySans.otf';

const iconFontStyles = `
@font-face {
  src: url(${MaterialCommunity_ttf});
  font-family: MaterialCommunityIcons;
}

@font-face {
  src: url(${Evil_ttf});
  font-family: EvilIcons;
}

@font-face {
  src: url(${Material_ttf}) format('truetype');
  font-family: MaterialIcons;
}

@font-face {
  src: url(${FontAwesome_ttf}) format('truetype');
  font-family: FontAwesome;
}

@font-face {
  src: url(${CircularStd_med_otf}) format('opentype');
  font-family: Circular Std;
}

@font-face {
  src: url(${SatoshiMedium}) format('opentype');
  font-family: Satoshi Medium;
}

@font-face {
  src: url(${SatoshiLight}) format('opentype');
  font-family: Satoshi Light;
}

@font-face {
  src: url(${SatoshiBlack}) format('opentype');
  font-family: Satoshi Black;
}

@font-face {
  src: url(${SatoshiRegular}) format('opentype');
  font-family: Satoshi;
}

@font-face {
  src: url(${ViceCitySans}) format('opentype');
  font-family: Vice City Sans;
}


`;

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) style.styleSheet.cssText = iconFontStyles;
else style.appendChild(document.createTextNode(iconFontStyles));

document.head.appendChild(style);
