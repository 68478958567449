import {
  CryptoEncoding,
  digestStringAsync,
  CryptoDigestAlgorithm,
} from 'expo-crypto';
import {
  CyberQuoteScreenName,
  LifeQuoteScreenName,
  PetQuoteScreenName,
  RentersQuoteScreenName,
} from '../../screen-config';
import { ProductLife } from '../../App/Constants/Products';

export function createExternalUserIdAsync(userId: string): Promise<string> {
  return digestStringAsync(CryptoDigestAlgorithm.SHA1, userId, {
    encoding: CryptoEncoding.HEX,
  });
}

export function createOrderIdAsync(products: Product[]): Promise<string> {
  const orderIds = products.map((p) => p.product_id).join('');
  return digestStringAsync(CryptoDigestAlgorithm.SHA1, orderIds, {
    encoding: CryptoEncoding.HEX,
  });
}

// This is a temporary funciton to seed Google Ads data. I know Summary page is missing and that is ok for now.
export function getProductViewedEvent(
  pageOrScreenName: string
): ProductViewedEvent {
  let id = null;
  let name = null;
  if (pageOrScreenName === CyberQuoteScreenName) {
    id = 'Cyber';
    name = 'Cyber';
  } else if (pageOrScreenName === RentersQuoteScreenName) {
    id = 'Renters';
    name = 'Renters';
  } else if (pageOrScreenName === PetQuoteScreenName) {
    id = 'Pet';
    name = 'Pet';
  } else if (pageOrScreenName === LifeQuoteScreenName) {
    id = ProductLife;
    name = ProductLife;
  }

  if (id && name) {
    return {
      product_id: id,
      name,
      category: name,
    };
  }
}
