// Define your flag names here
interface FlagNameTypes {
  cross_sell_screen: 'cross_sell_screen';
  redirect_to_pet_landing_page: 'redirect_to_pet_landing_page';
  renters_33_percent_off: 'renters_33_percent_off';
}

export type FlagName = keyof FlagNameTypes;

// This is used in the app so we're not using magic string externally
export const FLAG_NAMES: FlagNameTypes = {
  cross_sell_screen: 'cross_sell_screen',
  redirect_to_pet_landing_page: 'redirect_to_pet_landing_page',
  renters_33_percent_off: 'renters_33_percent_off',
};

type RemoteConfigTypes = {
  [key: string]: string | number | boolean;
};

// This is the local default values used before remote values are fetched
export const defaultFlagValues: RemoteConfigTypes = {
  cross_sell_screen: 'pet',
  redirect_to_pet_landing_page: false,
  renters_33_percent_off: false,
};
