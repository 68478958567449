import React from 'react';
import { UserTracking } from './UserTracking';

const UserTrackingContext = React.createContext<UserTracking>(null);

type UserTrackingProviderProps = {
  userTracking: UserTracking;
  children: React.ReactNode;
};

export function UserTrackingProvider({
  userTracking,
  children,
}: UserTrackingProviderProps): React.ReactElement {
  return (
    <UserTrackingContext.Provider value={userTracking}>
      {children}
    </UserTrackingContext.Provider>
  );
}

export const useUserTracking = () =>
  React.useContext<UserTracking>(UserTrackingContext);
