import { IsLifePolicy } from '../../App/Helper/PolicyHelper';
import { ApolloClient } from '@apollo/client';
import {
  GetDraftUserPolicies,
  GetDraftUserPolicies_draftUserPolicies_externalPolicy,
} from '../../../operation-result-types';
import { DRAFT_POLICIES } from '../../App/GraphQL/Waffle/Queries';

export async function getLifePolicyMetaDataAsync(
  apolloClient: ApolloClient<any>
): Promise<GetDraftUserPolicies_draftUserPolicies_externalPolicy> {
  const draftPolicyResponse = await apolloClient.query<GetDraftUserPolicies>({
    query: DRAFT_POLICIES,
    fetchPolicy: 'network-only',
  });
  return draftPolicyResponse?.data?.draftUserPolicies?.find(IsLifePolicy)
    ?.externalPolicy;
}
