import {
  getRemoteConfig,
  RemoteConfig,
  fetchAndActivate,
} from 'firebase/remote-config';
import { defaultFlagValues } from './flags';
import { initializeApp } from 'firebase/app';
import Sentry from '../../App/Sentry';
import { firebaseConfig, minimumFetchIntervalMinutes } from './flag.config';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import { Severity } from '@sentry/types';

export async function createFeatureFlagClient(): Promise<RemoteConfig> {
  try {
    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = minutesToMilliseconds(
      minimumFetchIntervalMinutes
    );
    remoteConfig.defaultConfig = defaultFlagValues;

    await fetchAndActivate(remoteConfig);
    return remoteConfig;
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setLevel(Severity.Warning);
      Sentry.captureException(e);
    });
  }
}
