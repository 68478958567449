import { UpdateUserTraitsProps } from './UpdateUserTraitsProps';

const KEYS = {
  age: 'age',
  agentName: 'agentName',
  cclid: 'cclid',
  credit: 'credit',
  gclid: 'gclid',
  gender: 'gender',
  married: 'married',
  mclid: 'mclid',
  utmSource: 'utmSource',
  utmCampaign: 'utmCampaign',
};

const getIsMarried = (params: URLSearchParams): boolean | undefined => {
  const marriedNum = parseInt(params.get(KEYS.married), 10);

  if (marriedNum === undefined || isNaN(marriedNum) || marriedNum === null) {
    return undefined;
  }

  return marriedNum === 1;
};

const getGender = (params: URLSearchParams): string | undefined => {
  if (!params.has(KEYS.gender)) return undefined;

  return params.get(KEYS.gender).toLocaleLowerCase();
};

export const getTraitsFromUrl = (): UpdateUserTraitsProps | undefined => {
  const params = new URLSearchParams(document.location.search);
  const traits: UpdateUserTraitsProps = {};

  const age = parseInt(params.get(KEYS.age), 10);
  if (age) traits.age = age;

  const credit = parseInt(params.get(KEYS.credit), 10);
  if (credit) traits.credit = credit;

  const gclid = params.get(KEYS.gclid);
  if (gclid) traits.gclid = gclid;

  const gender = getGender(params);
  if (gender) traits.gender = gender;

  const isMarried = getIsMarried(params);
  if (isMarried !== undefined) traits.isMarried = isMarried;

  const mclid = params.get(KEYS.mclid);
  if (mclid) traits[KEYS.mclid] = mclid;

  const agentName =
    params.get(KEYS.agentName) ??
    params.get(KEYS.agentName.toLocaleLowerCase());
  if (agentName) {
    traits[KEYS.agentName] = agentName;
  }

  const cclid = params.get(KEYS.cclid);
  if (cclid) traits[KEYS.cclid] = cclid;

  const utmSource = params.get('utm_source');
  if (utmSource) traits[KEYS.utmSource] = utmSource;

  const utmCampaign = params.get('utm_campaign');
  if (utmCampaign) traits[KEYS.utmCampaign] = utmCampaign;

  if (Object.keys(traits).length > 0) return traits;
};
