import React, { ReactNode } from 'react';
import { PathConfigMap } from '@react-navigation/native';

export const AuthLoadingScreenName = 'Auth Loading';
export const ArchMemberCenterScreenName = 'Arch Member Center';
export const BoostAdditionalInterestScreenName = 'Additional Interest';
export const CFMemberCenterScreenName = 'CF Member Center Screen';
export const CFMemberCenterPasswordScreenName = 'CF Member Center Password';
export const ChangePasswordScreenName = 'Change Password';
export const ChubbCompleteScreenName = 'Chubb Complete';
export const ChubFinalizingScreenName = 'Chubb Finalizing';
export const ChubbPaymentScreenName = 'Chubb Payment';
export const ClaimChatScreenName = 'Claim Chat';
export const ConnectToCFScreenName = 'Connect To CF';
export const ConnectToHippoScreeName = 'Connect to Hippo';
export const CreateAccountConfirmScreenName = 'Create Account Confirm';
export const CreateAccountContName = 'Create Account Cont';
export const CreateAccountInitialScreenName = 'Create Account Initial';
export const CreateLoginScreenName = 'Create Login';
export const CyberQuoteScreenName = 'Cyber Quote';
export const DailyLifeSaverScreenName = 'Daily Life Saver';
export const DisclosuresScreenName = 'Disclosures';
export const EditAccountScreenName = 'Edit Account';
export const ForgotPasswordScreenName = 'Forgot Password';
export const GiftScreenName = 'Gift';
export const HomeScreenName = 'Home';
export const LifeQuoteScreenName = 'Life Quote';
export const LifeBillingScreenName = 'Life Billing';
export const LifeQuoteQuestionsScreenName = 'Life Quote Questions';
export const LifeApplicationDeclined = 'Life Application Declined';
export const LifeApplicationScreenName = 'Life Application';
export const LifeApplicationReviewScreenName = 'Life Application Review';
export const LifeApplicationProcessingScreenName =
  'Life Application Processing';
export const LifeOfferScreenName = 'Life Offer';
export const LifeRedirectScreenName = 'Life Redirect';
export const LoginScreenName = 'Login';
export const ManagePlanScreenName = 'Manage Plan';
export const PetQuoteScreenName = 'Pet Quote';
export const PaymentInfoScreenName = 'Payment Info';
export const ProductChatScreenName = 'Product Chat';
export const ProductChooserScreenName = 'Product Chooser';
export const PurchaseCompleteScreenName = 'Step Complete';
export const ReferralScreenName = 'Referral';
export const ResetPasswordScreenName = 'Reset Password';
export const RentersQuoteScreenName = 'Renters Quote';
export const ReturnFromCFScreenName = 'Return From CF';
export const SimpleWebScreenName = 'Simple Web';
export const SettingsScreenName = 'Settings';
export const StartQuoteScreenName = 'Start Quote';
export const Step1ScreenName = 'Step 1';
export const StepMessageScreenName = 'Step Message';
export const SuggestedCoverageScreenName = 'Suggested Coverage';
export const UpdateGuestUserScreenName = 'Update Guest User';
export const WebScreenName = 'Web';
export const WelcomeScreenName = 'Welcome';
export const YourPolicyScreenName = 'Your Policy';

export type CreateAccountConfirmScreenParams = {
  initialMessage?: string | null;
  email: string | null;
};

export type CreateAccountInitialScreenParams = {
  initialEmail: string | null;
};

export type CreateLoginScreenParams = {
  initialEmail: string;
  products: string[] | undefined;
};

export type IncomingScreenParams = {
  productId: string | null;
};

export type LoginScreenParams = {
  initialMessage: string | null;
  initialEmail: string | null;
};

export type LifeApplicationScreenParams = {
  initialPage: string | null;
};

export type PurchaseCompleteScreenProps = {
  products: string[];
};

export type ResetPasswordScreenParams = {
  email: string | null;
};

export type SimpleWebScreenParams = {
  uri: string | null;
  loadingText?: string | null;
  headers?: Record<string, unknown> | null;
  method?: string | null;
  onMessage?: (event) => void;
  title: string | null;
  headerRight?: () => React.ReactNode | null;
  headerLeft?: () => React.ReactNode | null;
};

export type StartQuoteScreenProps = {
  productId: string | null;
};

export type StepMessageScreenProps = {
  nextScreen: keyof RootStackParamList | null;
  message: string | null;
  continueNavigation?: (screen: string) => Promise<void> | null;
};

type WebScreenHeaders = {
  Authorization: string;
  'X-Policy-ID': string;
};

export type WebScreenInitialParams = {
  uri: string | null;
  loadingText?: string | null;
  headers?: WebScreenHeaders | null;
  method?: string | null;
  onMessage?: (e) => void | null;
  title: string | null;
  headerRight?: ReactNode | null;
  headerLeft?: ReactNode | null;
};

export type CFMemberCenterPasswordScreenInitialParams = {
  last4: string | null;
  accountNumber: string | null;
};

export type RootStackParamList = {
  [AuthLoadingScreenName]: undefined;
  [ArchMemberCenterScreenName]: undefined;
  [BoostAdditionalInterestScreenName]: undefined;
  [CFMemberCenterScreenName]: undefined;
  [CFMemberCenterPasswordScreenName]: CFMemberCenterPasswordScreenInitialParams;
  [ChangePasswordScreenName]: undefined;
  [ChubbCompleteScreenName]: undefined;
  [ChubFinalizingScreenName]: undefined;
  [ChubbPaymentScreenName]: undefined;
  [ClaimChatScreenName]: undefined;
  [ConnectToCFScreenName]: undefined;
  [ConnectToHippoScreeName]: undefined;
  [CreateAccountConfirmScreenName]: CreateAccountConfirmScreenParams;
  [CreateAccountContName]: undefined;
  [CreateAccountInitialScreenName]: CreateAccountInitialScreenParams;
  [CreateLoginScreenName]: CreateLoginScreenParams;
  [CyberQuoteScreenName]: undefined;
  [DailyLifeSaverScreenName]: undefined;
  [DisclosuresScreenName]: undefined;
  [EditAccountScreenName]: undefined;
  [ForgotPasswordScreenName]: undefined;
  [GiftScreenName]: undefined;
  [HomeScreenName]: undefined;
  [LifeApplicationProcessingScreenName]: undefined;
  [LifeApplicationReviewScreenName]: undefined;
  [LifeApplicationScreenName]: LifeApplicationScreenParams;
  [LifeBillingScreenName]: undefined;
  [LifeOfferScreenName]: undefined;
  [LifeApplicationDeclined]: undefined;
  [LifeQuoteQuestionsScreenName]: undefined;
  [LifeQuoteScreenName]: undefined;
  [LifeRedirectScreenName]: undefined;
  [LoginScreenName]: LoginScreenParams;
  [ManagePlanScreenName]: undefined;
  [PaymentInfoScreenName]: undefined;
  [PetQuoteScreenName]: undefined;
  [ProductChooserScreenName]: undefined;
  [ProductChatScreenName]: undefined;
  [ReferralScreenName]: undefined;
  [ResetPasswordScreenName]: ResetPasswordScreenParams;
  [RentersQuoteScreenName]: undefined;
  [ReturnFromCFScreenName]: undefined;
  [SimpleWebScreenName]: SimpleWebScreenParams;
  [SettingsScreenName]: undefined;
  [StartQuoteScreenName]: StartQuoteScreenProps;
  [Step1ScreenName]: undefined;
  [PurchaseCompleteScreenName]: PurchaseCompleteScreenProps | undefined;
  [StepMessageScreenName]: StepMessageScreenProps;
  [SuggestedCoverageScreenName]: undefined;
  [UpdateGuestUserScreenName]: undefined;
  [WebScreenName]: WebScreenInitialParams;
  [WelcomeScreenName]: IncomingScreenParams;
  [YourPolicyScreenName]: undefined;
};

export const RouteConfig: {
  initialRouteName?: string;
  screens: PathConfigMap;
} = {
  screens: {
    [AuthLoadingScreenName]: 'auth-loading',
    [ArchMemberCenterScreenName]: 'arch-member-center',
    [BoostAdditionalInterestScreenName]: 'additional-interest',
    [CFMemberCenterScreenName]: 'cf-member-center',
    [ChangePasswordScreenName]: 'change-password',
    [ChubbCompleteScreenName]: 'cyber-complete',
    [ChubFinalizingScreenName]: 'cyber-finalizing',
    [ChubbPaymentScreenName]: 'cyber-payment',
    [ClaimChatScreenName]: 'claim-chat',
    [ConnectToCFScreenName]: 'connect-to-aspca',
    [ConnectToHippoScreeName]: 'connect-to-hippo',
    [CreateAccountConfirmScreenName]: 'create-account-confirm',
    [CreateAccountContName]: 'create-account-cont',
    [CreateAccountInitialScreenName]: 'create-account-initial',
    [CreateLoginScreenName]: 'create-login',
    [CyberQuoteScreenName]: 'cyber-quote',
    [RentersQuoteScreenName]: 'renters-quote',
    [DailyLifeSaverScreenName]: 'daily-saver',
    [DisclosuresScreenName]: 'disclosures',
    [EditAccountScreenName]: 'change-address',
    [ForgotPasswordScreenName]: 'forgot-password',
    [GiftScreenName]: 'gift',
    [HomeScreenName]: 'home',
    [LifeQuoteScreenName]: 'life-quote',
    [LifeApplicationProcessingScreenName]: 'life-application-processing',
    [LifeApplicationReviewScreenName]: 'life-application-review',
    [LifeApplicationScreenName]: 'life-application',
    [LifeBillingScreenName]: 'life-billing',
    [LifeOfferScreenName]: 'life-offer',
    [LifeApplicationDeclined]: 'life-quote-declined',
    [LifeQuoteQuestionsScreenName]: 'life-quote-questions',
    [LifeRedirectScreenName]: 'life-redirect',
    [LoginScreenName]: 'login',
    [ManagePlanScreenName]: 'manage-plan',
    [PaymentInfoScreenName]: 'payment-info',
    [PetQuoteScreenName]: 'pet-quote',
    [ProductChooserScreenName]: 'product-chooser',
    [ProductChatScreenName]: 'product-chat',
    [PurchaseCompleteScreenName]: 'step-complete',
    [ReferralScreenName]: 'referral',
    [ResetPasswordScreenName]: 'reset-password',
    [ReturnFromCFScreenName]: 'return-from-aspca',
    [SimpleWebScreenName]: 'simple-web',
    [SettingsScreenName]: 'settings',
    [StartQuoteScreenName]: 'start',
    [Step1ScreenName]: 'step1',
    [StepMessageScreenName]: 'step-message',
    [SuggestedCoverageScreenName]: 'suggested-coverage',
    [UpdateGuestUserScreenName]: 'update-user',
    [WebScreenName]: 'web',
    [WelcomeScreenName]: 'welcome',
    [YourPolicyScreenName]: 'your-policy',
  },
};
