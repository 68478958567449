import { UpdateUserTraitsProps } from './UpdateUserTraitsProps';
import { Analytics } from '@segment/analytics-next';

declare let analytics: Analytics | null;

export const sharedUpdateUserTraits = (userInput: UpdateUserTraitsProps) => {
  const { city, zip: postalCode, state, address: street } = userInput;
  const address = {
    city,
    postalCode,
    state,
    street,
  };

  const {
    dateOfBirth: birthday,
    email,
    firstName,
    lastName,
    phone,
  } = userInput;

  const userTraits: TrackedUserTraits = {
    birthday,
    email,
    firstName,
    lastName,
    phone,
  };

  if (Object.keys(address).length > 0) {
    userTraits.address = address;
  }
  analytics?.identify(null, userTraits);
};
