/*
  This file is for generating user tracking events to Segment(our CDP) which
  forwards things to our downstream systems
 */
import { Analytics } from '@segment/analytics-next';
import { FinalizedPolicy } from '../../component-config';
import {
  FinalizeUserPolicies2_finalizeDraftUserPolicies_responses,
  GetDraftUserPolicies_draftUserPolicies,
  GetFinalizedUserPolicies_finalizedUserPolicies,
  GetProducts_products,
} from '../../../operation-result-types';
import { UpdateUserTraitsProps } from './UpdateUserTraitsProps';
import { createExternalUserIdAsync, getProductViewedEvent } from './utils';
import { UserTracking } from './UserTracking';
import Sentry from '../../App/Sentry';
import { UpdateLifeTraitsProps } from './UpdateLifeTraitProps';
import { mapLifeTraitsToTrackingTraits } from './mapLifeTraitsToTrackingTraits';
import { ProductName } from '../../App/Constants/Products';
import { isNil, omitBy } from 'lodash-es';
import { Severity } from '@sentry/types';
import { sharedUpdateUserTraits } from './shared-update-user-traits';
import { AmplitudeClient } from 'amplitude-js';

async function wait(seconds: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, seconds * 1000);
  });
}

declare global {
  interface Window {
    analytics: Analytics | null;
    amplitude: AmplitudeClient | null;
  }
}

// This gets defined in index.html

export default class WebUserTracking implements UserTracking {
  initTracking(_: string): Promise<void> {
    // no-op
    // this is initialized in index.html b/c we want to init it
    // ASAP to gather metrics
    return new Promise((resolve, reject) => {
      try {
        window.analytics.ready(() => {
          // @ts-ignore
          window?.amplitude
            ?.getInstance()
            ?.setVersionName(process.env.APP_VERSION);
        });
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setLevel(Severity.Warning);
          Sentry.captureException(e);
        });
      } finally {
        resolve();
      }
    });
  }

  linkAnonymousUserToCreatedUser(
    internalUserId: string,
    userTraits?: UpdateUserTraitsProps
  ): void {
    createExternalUserIdAsync(internalUserId)
      .then((publicUserId) => {
        if (userTraits) {
          window.analytics
            ?.identify(publicUserId, userTraits)
            .then(() => {
              sharedUpdateUserTraits(userTraits);
            })
            .catch();
        } else {
          window.analytics?.identify(publicUserId);
        }
      })
      .catch((e) => {
        Sentry.withScope((scope) => {
          scope.setLevel(Severity.Warning);
          Sentry.captureException(e);
        });
      });
  }

  publishCyberOrderCompleted(policy: FinalizedPolicy): void {
    try {
      const props: OrderCompletedEvent = {
        order_id: policy.id,
        total: policy.price,
        currency: 'USD',
        products: [
          {
            product_id: policy.id,
            category: policy.productId,
            name: policy.productId,
            price: policy.price,
            revenue: policy.price,
          },
        ],
      };
      window.analytics?.track('Order Completed', props);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishLifeInsuranceAppQuestionAnswered(percent: number): void {
    try {
      window.analytics?.track('Life Application Question Answered', {
        percentComplete: Math.round(percent * 100),
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  async publishLifeOrderCompleted(
    waffleLifePolicy: FinalizeUserPolicies2_finalizeDraftUserPolicies_responses,
    price: number
  ): Promise<void> {
    try {
      const orderCompletedEvent: OrderCompletedEvent = {
        order_id: waffleLifePolicy.policyId,
        total: price,
        currency: 'USD',
        products: [
          {
            product_id: waffleLifePolicy.productId,
            category: waffleLifePolicy.productId,
            name: waffleLifePolicy.productId,
            price,
            revenue: price,
          },
        ],
      };

      await window.analytics?.track('Order Completed', orderCompletedEvent);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  publishOrderCompleted(
    policies: GetDraftUserPolicies_draftUserPolicies[] = []
  ): void {
    try {
      const orderCompletedEvents: OrderCompletedEvent[] = policies.map((p) => ({
        order_id: p.id,
        total: p.price,
        currency: 'USD',
        products: [
          {
            product_id: p.id,
            category: p.productId,
            name: p.productId,
            price: p.price,
            revenue: p.price,
          },
        ],
      }));

      for (const orderCompletedEvent of orderCompletedEvents) {
        window.analytics?.track('Order Completed', orderCompletedEvent);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  async publishOrderTransferred(
    policy?: GetFinalizedUserPolicies_finalizedUserPolicies
  ): Promise<void> {
    try {
      if (policy) {
        const product: Product = {
          product_id: policy.id,
          category: policy.productId,
          name: policy.productId,
          price: policy.price,
          revenue: policy.price,
        };

        await window.analytics?.track('Order Transferred', product);
        await wait(1);
      }
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishProductListViewed(products: GetProducts_products[] = []): void {
    try {
      // @ts-ignore Ignoring for now b/c we don't have all product information at this point
      const _products: Product[] = products
        .filter((p) => p.eligibility.isEligible)
        .map((p, index) => ({
          product_id: p.id,
          name: p.name,
          order: index,
        }));

      const list_id = products
        .filter((p) => p.eligibility.isEligible)
        .map((p) => p.id)
        .sort()
        .join('');

      const productListViewedEvent: ProductListViewedEvent = {
        list_id,
        products: _products,
      };

      window.analytics?.track('Product List Viewed', productListViewedEvent);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishProductsAdded(selectedProducts: GetProducts_products[]): void {
    try {
      for (let i = 0; i < selectedProducts.length; i++) {
        const selectedProduct = selectedProducts[i];
        window.analytics?.track('Product Added', {
          name: selectedProduct.name,
          category: selectedProduct.name,
          position: i,
        });
      }
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishExperimentViewed(props: PublishExperimentProps): void {
    try {
      window.analytics?.track('Experiment Viewed', props);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishProductViewed(routeName: string): void {
    try {
      const productViewedEvent = getProductViewedEvent(routeName);
      if (productViewedEvent) {
        window.analytics?.track('Product Viewed', productViewedEvent);
      }
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishPromotionClicked(promo: 'Cyber' | 'Pet'): void {
    try {
      window.analytics?.track('Promotion Clicked', {
        id: promo,
        name: promo,
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishPromotionSwiped(promo: 'Cyber' | 'Pet', position: number): void {
    try {
      window.analytics?.track('Promotion Swiped', {
        id: promo,
        name: promo,
        position,
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishPromotionViewed(promo: 'Cyber' | 'Pet'): void {
    try {
      window.analytics?.track('Promotion Viewed', {
        id: promo,
        name: promo,
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  updateLifeInsuranceTraits(userTraits: UpdateLifeTraitsProps): void {
    try {
      window.analytics?.identify(mapLifeTraitsToTrackingTraits(userTraits));
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  updateUserEmail(email: string): void {
    try {
      window.analytics?.identify({
        email,
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  _getAddress(userInput: UpdateUserTraitsProps): TrackingAddress | undefined {
    const { city, zip: postalCode, state, address: street } = userInput;
    const address = {
      city,
      postalCode,
      state,
      street,
    };

    if (Object.keys(address).length > 0) {
      return address;
    }
  }

  updateUserTraits(userInput: UpdateUserTraitsProps): void {
    try {
      const {
        city,
        zip: postalCode,
        state,
        address: street,
        dateOfBirth: birthday,
        ...restOfTraits
      } = userInput;

      const address = omitBy(
        {
          city,
          postalCode,
          state,
          street,
        },
        isNil
      );

      const userTraits: TrackedUserTraits = {
        birthday,
        address,
        ...restOfTraits,
      };

      if (Object.keys(address).length > 0) {
        userTraits.address = address;
      }

      window.analytics?.identify(omitBy(userTraits, isNil));
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  viewedScreenOrPage(name: string): void {
    try {
      window.analytics?.page(name);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  async publishExternalPolicyClicked(
    type: ProductName,
    provider: string
  ): Promise<void> {
    try {
      await window.analytics?.track('External Policy Link Clicked', {
        type,
        provider,
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  publishQuoteQuestionSectionAnswered(section: number) {
    try {
      window.analytics
        ?.track('Life Quote Question Section Answered', {
          section,
        })
        .then()
        .catch((e) => {
          Sentry.withScope((scope) => {
            scope.setLevel(Severity.Warning);
            Sentry.captureException(e);
          });
        });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }

  reset(): void {
    try {
      window.amplitude?.setUserId(null);
      window.amplitude?.regenerateDeviceId();
      window.amplitude?.getInstance().resetSessionId();
      // This for some reason DOES NOT reset the anonymousId eventhough it should.
      // This should be ok for now. This affects what the warehouse looks like for agent type of workflows.
      // It means anonymousIds will be shared amongst multiple users in the warehouse when an agent is creating policies
      window.analytics?.reset();
      window.analytics?.user().logout();
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
    }
  }
}
