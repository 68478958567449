import { FlagName } from './flags';
import {
  fetchAndActivate,
  getValue,
  RemoteConfig,
  Value,
} from 'firebase/remote-config';
import { useContext, useEffect, useState } from 'react';
import Sentry from '../../App/Sentry';
import { FeatureFlagContext } from './flag-provider';
import minutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import { intervalOffset, minimumFetchIntervalMinutes } from './flag.config';
import { Severity } from '@sentry/types';

/**
 * Makes the last fetched config available to the getters.
 * @param flagName - The {@link FlagName} instance. Flag name or experiment id.
 * @param shouldSync - Optional param to sync with remote config.
 * @returns boolean returns a boolean value that syncs with a remote config.
 *
 * @example
 * const crossSellScreen = useFlagBooleanValue(FLAG_NAMES.cross_sell_screen, true);
 */
export const useFlagBooleanValue = (
  flagName: FlagName,
  defaultValue: boolean,
  shouldSync?: boolean
): boolean => {
  function getValueFromCache(featureFlagClient: RemoteConfig) {
    try {
      const rawValue: Value = getValue(featureFlagClient, flagName as string);
      return rawValue.asBoolean();
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
      return defaultValue;
    }
  }

  return useFeatureFlag<boolean>({
    flagName,
    defaultValue,
    getValueFromCache,
    shouldSync,
  });
};

export const useFlagNumberValue = (
  flagName: FlagName,
  defaultValue: number,
  shouldSync?: boolean
): number => {
  function getValueFromCache(featureFlagClient: RemoteConfig) {
    try {
      const rawValue: Value = getValue(featureFlagClient, flagName as string);
      return rawValue.asNumber();
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
      return defaultValue;
    }
  }

  return useFeatureFlag<number>({
    flagName,
    defaultValue,
    getValueFromCache,
    shouldSync,
  });
};

export const useFlagStringValue = (
  flagName: FlagName,
  defaultValue: string,
  shouldSync?: boolean
): string => {
  function getValueFromCache(featureFlagClient: RemoteConfig) {
    try {
      const rawValue: Value = getValue(featureFlagClient, flagName as string);
      return rawValue.asString();
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setLevel(Severity.Warning);
        Sentry.captureException(e);
      });
      return defaultValue;
    }
  }

  return useFeatureFlag<string>({
    flagName,
    defaultValue,
    getValueFromCache,
    shouldSync,
  });
};

type FeatureFlagHookProps<FlagValueType> = {
  flagName: FlagName;
  defaultValue: FlagValueType;
  getValueFromCache: (
    featureFlagClient: RemoteConfig,
    flagName: FlagName
  ) => FlagValueType;
  shouldSync?: boolean;
};

function useFeatureFlag<FlagValueType>({
  flagName,
  defaultValue,
  shouldSync,
  getValueFromCache,
}: FeatureFlagHookProps<FlagValueType>): FlagValueType {
  const featureFlagClient = useContext(FeatureFlagContext) as RemoteConfig;
  const [value, setValue] = useState(
    getValueFromCache(featureFlagClient, flagName)
  );

  useEffect(() => {
    if (shouldSync) {
      const intervalSub = setInterval(() => {
        (async () => {
          try {
            await fetchAndActivate(featureFlagClient);
            const _value = getValueFromCache(featureFlagClient, flagName);
            if (_value !== value) {
              setValue(_value);
            }
          } catch (e) {
            Sentry.withScope((scope) => {
              scope.setLevel(Severity.Warning);
              Sentry.captureException(e);
            });
            setValue(defaultValue);
          }
        })();
      }, minutesToMilliseconds(minimumFetchIntervalMinutes) + intervalOffset);

      return function cleanup() {
        clearInterval(intervalSub);
      };
    }
  }, []);

  return value;
}
