import { FirebaseOptions } from 'firebase/app';
import { Platform } from 'react-native';
import Config from 'react-native-config';

export const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.FIREBASE_API_KEY,
  appId: process.env.FIREBASE_APP_ID,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
};

const minutesAsString =
  Platform.OS === 'web'
    ? process.env.FIREBASE_MINIMUM_FETCH_INTERVAL_MINUTES
    : Config.FIREBASE_MINIMUM_FETCH_INTERVAL_MINUTES;
export const minimumFetchIntervalMinutes = minutesAsString
  ? parseInt(minutesAsString, 10)
  : 60;

// This is used to offset the fetch interval so we aren't hitting the set interval minimum
export const intervalOffset = 5000;
