import React, { useState } from 'react';
interface LifeProviderState {
  redirectReason?: string;
}

interface Context extends LifeProviderState {
  setRedirectReason: (reason: string) => void;
}

const LifeInsuranceContext = React.createContext<Context>(null);

type LifeInsuranceProviderProps = {
  children: React.ReactNode;
};

export function LifeInsuranceProvider({
  children,
}: LifeInsuranceProviderProps): React.ReactElement {
  const [lifeContextState, setLifeContextState] = useState<LifeProviderState>({
    redirectReason: '',
  });

  function setRedirectReason(reason: string) {
    setLifeContextState({ ...lifeContextState, redirectReason: reason });
  }

  return (
    <LifeInsuranceContext.Provider
      value={{
        ...lifeContextState,
        setRedirectReason,
      }}>
      {children}
    </LifeInsuranceContext.Provider>
  );
}

export const useLifeInsurance = () => React.useContext(LifeInsuranceContext);
