import React from 'react';
import { RemoteConfig } from 'firebase/remote-config';
import { FirebaseRemoteConfigTypes } from '@react-native-firebase/remote-config';

export const FeatureFlagContext = React.createContext<
  RemoteConfig | FirebaseRemoteConfigTypes.Module
>(null);

type FlagProviderProps = {
  children: React.ReactNode;
  featureFlagClient: RemoteConfig | FirebaseRemoteConfigTypes.Module;
};

export function FlagProvider({
  children,
  featureFlagClient,
}: FlagProviderProps): React.ReactElement {
  return (
    <FeatureFlagContext.Provider value={featureFlagClient}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
