import { UpdateLifeTraitsProps } from './UpdateLifeTraitProps';

export const mapLifeTraitsToTrackingTraits = (
  lifeTraits: UpdateLifeTraitsProps
): TrackedUserTraits => {
  const {
    annualIncome,
    lastName,
    firstName,
    dateOfBirth,
    zipCode,
    email,
    gender,
  } = lifeTraits;

  return {
    annualIncome,
    lastName,
    firstName,
    birthday: dateOfBirth,
    address: {
      postalCode: zipCode,
    },
    email,
    gender,
  };
};
